.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #888;
}

.no-data-icon {
  font-size: 48px;
  margin-bottom: 10px;
}

.no-data p {
  font-size: 18px;
}
