.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: transparent;
 
  }
  
  .loader {
    border: 6px solid rgba(0, 0, 0, 0.3);
    border-top: 6px solid #007bff; /* Adjust the color to match your theme */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite; /* Apply the spin animation */
    
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  h2 {
    font-size: 18px;
    color: #007bff; /* Adjust the color to match your theme */
    margin: 0;
  }
  