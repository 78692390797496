.custom-file-upload {
    display: inline-block;
    cursor: pointer;
    padding: 10px;
    background-color: #f1f1f1;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .custom-file-upload:hover {
    background-color: #e1e1e1;
  }
  
  .custom-file-upload input {
    display: none;
  }
  
  .custom-file-upload span {
    font-weight: bold;
  }
  .input-with-icon {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
  }
  
  .input-with-icon i {
    margin-right: 10px;
    color: #999;
  }
  
  .input-with-icon input {
    flex: 1;
    border: none;
    outline: none;
  }
  