/* style.css */

/* Style for the entire MainCard component */
.card-container {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  min-width: "90%";
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

/* Style for the header section */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.date-input-container {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.select-input {
  width: 200px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 10px;
}

.date-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

/* Style for the Share and Upload buttons */
.buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.share-button,
.upload-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px;
}

.share-button:hover,
.upload-button:hover {
  background-color: #0056b3;
}
/* style.css */
.custom-select {
  /* Add your custom styles for the select input */
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ccc;
  padding: 5px;
  font-size: 16px;
  border-radius: 5px;
}

.custom-date {
  /* Add your custom styles for the date input */
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ccc;
  padding: 5px;
  font-size: 16px;
  border-radius: 5px;
}
