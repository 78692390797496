.dropzone {
    border: 2px dashed #cccccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin: 10px;
  }
  
  .dropzone p {
    margin: 10px 0 0;
  }
  
  .dropzone .MuiSvgIcon-root {
    margin-bottom: 10px;
  }